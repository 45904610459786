.toursSection {
  padding-top: 50px;
  padding-bottom: 50px;
}
/* .toursSection::before{
    content: '';
    width: 800px;
    height: 700px;
    position: absolute;
    top: 100px;
    left: 50px;
    background-color: #697739;
} */
.aboutSection {
    padding-top: 100px;
    padding-bottom: 100px;
  background: rgba(105, 119, 57, 0.9);
  background: linear-gradient(
    180deg,
    rgba(11, 31, 35, 1) 0%,
    rgba(11, 31, 35, 1) 5%,
    rgba(105, 119, 57, 0.9) 100%
  );
}
.excursionsSection {
  background: rgba(105, 119, 57, 0.9);
  background: linear-gradient(
    180deg,
    rgba(105, 119, 57, 0.9) 0%,
    rgba(105, 119, 57, 0.9) 5%,
    rgba(187, 220, 129, 0.9) 100%
  );
}
@media screen and (max-width:800px){
    .aboutSection {
        padding-top: 50px;
    padding-bottom: 50px;
      background: #bbdc81;
    }
}
.aboutImageContainer{
    display: block;
}
.aboutImageContainer img {
    width:100%;
}

.travelPartnerSection{
    background: rgba(234, 229, 225, 0.9);
  /* background: linear-gradient(
    180deg,
    rgba(105, 119, 57, 0.9) 0%,
    rgba(105, 119, 57, 0.9) 15%,
    rgba(234, 229, 225, 0.9) 100%
  ); */
}

.h2 > a{
    text-decoration: none;
    color: inherit;
}

.contactSection{
  position: relative;
}

.ContactNavCont{
  display: block;
  position: relative;
}
.ContactNavCont .mainNav{
  transform: translateY(0);
}
.ContactNavCont .mainNav > li {
  text-align: left;
}
.ContactNavCont .mainNav > li > a{
  color: white;
  text-align: left;
  font-size: 1.5rem;
}
.floatList{
  width:auto;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.floatList>li {
  width: auto !important;
  float: left;
  clear: none !important;
}
.home-image-cont{
  display: block;
  position: relative;
  height: 800px;
  overflow: hidden;
}
.home-image-cont img{
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.home-image-cont h2{
  position: absolute;
  width: 100%;
  bottom: 0;
}

@media screen and (max-width: 800px) {
  .home-image-cont{
    height: 600px;
  }
  .home-image-cont img{
    width: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
}

.button-rounded{
  border:none;
  border-radius: 25px !important;
}
.insuarance-banner{
  display: block;
  min-height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
  /* background-color: black; */
  z-index: 99;
  margin-bottom: -75px;
  
}
.insuarance-banner .img-cont{
  width: auto;
  height: auto;
  max-height: 150px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.testimonialCard{
  text-align: center;
  padding: 15px;
  border-radius: 0px;
  height: 100%;
}
.testimonialImange{
  display: block;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}
.stars{
  font-size: 8em;
  color: #34e0a1;
  line-height: 10px;
}
.carousel-item-padding-40-px{
  /* margin: 15px; */
  padding: 15px;
}