@import url("https://fonts.googleapis.com/css2?family=Rozha+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
.mainSection {
  width: 100%;
  min-height: auto;
  position: relative;
}
.body {
  background-color: #eae5e1;
}
.color-white {
  color: #fff !important;
}
.color-white:hover {
  color: #fff;
}
.color-green {
  color: #0b1f23 !important;
}
.color-green3{
  color:#4e5c36;
}
.color-green4{
  color: #768149;
}
.color-yellow{
  color:#FEC556 !important;
}
.bg-dark-green {
  background-color: #0b1f23;
}
.bg-light-green {
  background-color: #697739;
}
.bg-light-green1 {
  background-color: #5b6d20;
}
.bg-light-green2 {
  background-color: #bbdc81;
}
.bg-light-green3 {
  background-color: #8baa56;
}
.bg-light-green4{
  background-color:#768149;
}

.bg-yellow {
  background-color: #FEC556 !important;
}
.h1,
.h2,
.h3 {
  font-family: "Rozha One", serif !important;
}
.h1-main{
  font-size: 3.5rem !important;
}
.h2-main {
  font-size: 2.5rem !important;
}
.big-text{
  font-size: 3.5rem !important;
}
.small-text{
  font-size: 2rem;;
}
@media screen and (max-width:800px){
  .h1-main{
    font-size: 2rem !important;
  }
  .h2-main {
    font-size: 1.8rem !important;
  }
  .small-text{
    font-size: 1.5rem;;
  }
  .big-text{
    font-size: 2rem !important;
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.small {
  font-size: 1rem !important;
}

.padding-side-0{
  padding-left:0;
  padding-right: 0;
}

.padding-side-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.padding-left-25{
  padding-left: 25px !important;
}
.padding-right-25{
  padding-right: 25px !important;
}
.padding-over-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.padding-top-25 {
  padding-top: 25px !important;
}

.padding-bottom-25 {
  padding-bottom: 25px !important;
}


.padding-side-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.padding-left-50{
  padding-left: 50px !important;
}
.padding-right-50{
  padding-right: 50px !important;
}
.padding-over-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.padding-top-50 {
  padding-top: 50px !important;
}

.padding-bottom-50 {
  padding-bottom: 50px !important;
}

.content-align-center{
  display: flex !important;
  flex-direction: column;
  flex-basis: content;
  justify-content: center !important;
}


.react-multiple-carousel__arrow{
  z-index: 98;
}
.tripadvisor-cc{
  display: block;
  width: fit-content;
  height: 100px;
  justify-self: center;
  overflow: hidden;
}

.tripadvisor-cc img{
  height: 100%;
}
a{
  text-decoration: none !important;
}